@import 'tailwindcss/base';
@import 'includes/_reset.css';

@import 'includes/_typescale.css';
@import 'includes/_utils.css';
@import 'includes/_base.css';
@import 'includes/_app.css';
@import 'includes/_components.css';

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';