@layer utilities {
	.font-display,
	.font-serif,
	.font-sans {
		color: #f0f !important;
	}

	.underline {
		text-decoration-skip-ink: auto;
	}

	/* .font-display {
		font-variant-ligatures: common-ligatures;
	}
	.text-small {
		font-size: 0.75rem;
	}
	.text-menu {
		@apply text-[1.4rem] md:text-[1.5rem];
		line-height: 1;
	}
	.text-body {
		font-size: 1.125rem;
		line-height: 1.278;
	}
	.text-body--large {
		font-size: 1.375rem;
		line-height: 1.273;
	}
	.text-h2 {
		font-size: 2.813rem;
	}
	.text-h3 {
		font-size: 1.125rem;
	}
	.text-h4 {
		font-size: 1.5rem;
	}
	.text-pullquote {
		font-size: fluid(32px, 42px);
	}
	.text-page-header {
		font-size: fluid(52px, 68px);
		@apply font-display leading-115 md:leading-110 text-balance;
	}
	.large-fluid-type {
		@apply font-display text-[10vw] leading-115 md:leading-110 text-balance [&_em]:italic;
	} */

	.style-h1 {
		@apply font-new-display text-[4.25rem] sm:[font-size:min(10vw,148px)] leading-[1.4] -tracking-10 text-balance;
		font-variant-ligatures: common-ligatures;

		&.page-header {
			@apply leading-[0.7em];
		}
	}

	.style-h2 {
		@apply font-new-display text-[46px] leading-[1.08695652173913] md:text-[68px] md:leading-[1.088235294117647] -tracking-10;
		font-variant-ligatures: common-ligatures;

		&.italic {
			@apply tracking-0;
		}
	}

	.style-h3 {
		@apply font-new-sans text-[18px] leading-[1.111111111111111] tracking-100 uppercase;
	}

	.style-h4 {
		@apply font-new-sans text-[20px] leading-[1.2] md:text-[24px] md:leading-[1.25];
	}

	.style-body--xl {
		@apply font-new-serif text-[40px] leading-[1.175];
	}

	.style-body--lg {
		@apply font-new-serif text-[24px] leading-[1.185185185185185];

		&.italic {
			@apply tracking-5;
		}
	}

	.style-body {
		@apply font-new-serif text-[20px] leading-[1.136363636363636];

		&.italic {
			@apply tracking-5;
		}
	}

	.style-nav-item {
		@apply font-new-sans text-[20px] leading-[1.2] md:text-[22px] md:leading-[1.25];
	}

	.style-caption {
		@apply font-new-sans text-[16px] sm:text-[18px] leading-[1.166666666666667];
	}

	.style-caption-small {
		@apply font-new-sans text-[16px] leading-[1.166666666666667];
	}

	.style-tag {
		@apply font-new-sans text-[14px] leading-[1] tracking-5;
	}

	.style-tag--lg {
		@apply font-new-sans text-[18px] leading-[1] tracking-5;
	}
}
