/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/447d4b11d83eb283-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e2971adc568300e6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fffbbefba38696a5-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4f61058f92fa778c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/42b03bd58279f357-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c1a1ca61063e9eea-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8ea6d73be4df1015-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5536c2b71f31f4c7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/05d30fccf6dfb4ed-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f2fa6d7398e61d46-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/69f639318a288687-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Mono_2eca2c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/12d86e8d7e1c2769-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Mono_Fallback_2eca2c';src: local("Arial");ascent-override: 77.08%;descent-override: 19.93%;line-gap-override: 0.00%;size-adjust: 135.95%
}.__className_2eca2c {font-family: '__Roboto_Mono_2eca2c', '__Roboto_Mono_Fallback_2eca2c';font-weight: 400
}.__variable_2eca2c {--font-temp: '__Roboto_Mono_2eca2c', '__Roboto_Mono_Fallback_2eca2c'
}

@font-face {
font-family: '__untitled_937a82';
src: url(/_next/static/media/55735ebace760632-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__untitled_Fallback_937a82';src: local("Arial");ascent-override: 95.30%;descent-override: 23.49%;line-gap-override: 0.00%;size-adjust: 105.15%
}.__className_937a82 {font-family: '__untitled_937a82', '__untitled_Fallback_937a82';font-weight: 400;font-style: normal
}.__variable_937a82 {--font-untitled: '__untitled_937a82', '__untitled_Fallback_937a82'
}

@font-face {
font-family: '__newBaskerville_bfa1d0';
src: url(/_next/static/media/52f5fb986c3483b0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__newBaskerville_bfa1d0';
src: url(/_next/static/media/46c9d678de212359-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__newBaskerville_Fallback_bfa1d0';src: local("Arial");ascent-override: 68.89%;descent-override: 33.62%;line-gap-override: 20.50%;size-adjust: 97.55%
}.__className_bfa1d0 {font-family: '__newBaskerville_bfa1d0', '__newBaskerville_Fallback_bfa1d0'
}.__variable_bfa1d0 {--font-new-baskerville: '__newBaskerville_bfa1d0', '__newBaskerville_Fallback_bfa1d0'
}

@font-face {
font-family: '__baskervilleDisplay_82d014';
src: url(/_next/static/media/1f54fff59c3bcead-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__baskervilleDisplay_82d014';
src: url(/_next/static/media/5928d6c04a526ead-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__baskervilleDisplay_Fallback_82d014';src: local("Arial");ascent-override: 97.92%;descent-override: 36.97%;line-gap-override: 0.00%;size-adjust: 95.49%
}.__className_82d014 {font-family: '__baskervilleDisplay_82d014', '__baskervilleDisplay_Fallback_82d014'
}.__variable_82d014 {--font-baskerville-display: '__baskervilleDisplay_82d014', '__baskervilleDisplay_Fallback_82d014'
}

*, ::before, ::after{
	--tw-border-spacing-x: 0;
	--tw-border-spacing-y: 0;
	--tw-translate-x: 0;
	--tw-translate-y: 0;
	--tw-rotate: 0;
	--tw-skew-x: 0;
	--tw-skew-y: 0;
	--tw-scale-x: 1;
	--tw-scale-y: 1;
	--tw-pan-x:  ;
	--tw-pan-y:  ;
	--tw-pinch-zoom:  ;
	--tw-scroll-snap-strictness: proximity;
	--tw-gradient-from-position:  ;
	--tw-gradient-via-position:  ;
	--tw-gradient-to-position:  ;
	--tw-ordinal:  ;
	--tw-slashed-zero:  ;
	--tw-numeric-figure:  ;
	--tw-numeric-spacing:  ;
	--tw-numeric-fraction:  ;
	--tw-ring-inset:  ;
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: rgba(59, 130, 246, 0.5);
	--tw-ring-offset-shadow: 0 0 rgba(0,0,0,0);
	--tw-ring-shadow: 0 0 rgba(0,0,0,0);
	--tw-shadow: 0 0 rgba(0,0,0,0);
	--tw-shadow-colored: 0 0 rgba(0,0,0,0);
	--tw-blur:  ;
	--tw-brightness:  ;
	--tw-contrast:  ;
	--tw-grayscale:  ;
	--tw-hue-rotate:  ;
	--tw-invert:  ;
	--tw-saturate:  ;
	--tw-sepia:  ;
	--tw-drop-shadow:  ;
	--tw-backdrop-blur:  ;
	--tw-backdrop-brightness:  ;
	--tw-backdrop-contrast:  ;
	--tw-backdrop-grayscale:  ;
	--tw-backdrop-hue-rotate:  ;
	--tw-backdrop-invert:  ;
	--tw-backdrop-opacity:  ;
	--tw-backdrop-saturate:  ;
	--tw-backdrop-sepia:  ;
	--tw-contain-size:  ;
	--tw-contain-layout:  ;
	--tw-contain-paint:  ;
	--tw-contain-style:  ;
}
::backdrop{
	--tw-border-spacing-x: 0;
	--tw-border-spacing-y: 0;
	--tw-translate-x: 0;
	--tw-translate-y: 0;
	--tw-rotate: 0;
	--tw-skew-x: 0;
	--tw-skew-y: 0;
	--tw-scale-x: 1;
	--tw-scale-y: 1;
	--tw-pan-x:  ;
	--tw-pan-y:  ;
	--tw-pinch-zoom:  ;
	--tw-scroll-snap-strictness: proximity;
	--tw-gradient-from-position:  ;
	--tw-gradient-via-position:  ;
	--tw-gradient-to-position:  ;
	--tw-ordinal:  ;
	--tw-slashed-zero:  ;
	--tw-numeric-figure:  ;
	--tw-numeric-spacing:  ;
	--tw-numeric-fraction:  ;
	--tw-ring-inset:  ;
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: rgba(59, 130, 246, 0.5);
	--tw-ring-offset-shadow: 0 0 rgba(0,0,0,0);
	--tw-ring-shadow: 0 0 rgba(0,0,0,0);
	--tw-shadow: 0 0 rgba(0,0,0,0);
	--tw-shadow-colored: 0 0 rgba(0,0,0,0);
	--tw-blur:  ;
	--tw-brightness:  ;
	--tw-contrast:  ;
	--tw-grayscale:  ;
	--tw-hue-rotate:  ;
	--tw-invert:  ;
	--tw-saturate:  ;
	--tw-sepia:  ;
	--tw-drop-shadow:  ;
	--tw-backdrop-blur:  ;
	--tw-backdrop-brightness:  ;
	--tw-backdrop-contrast:  ;
	--tw-backdrop-grayscale:  ;
	--tw-backdrop-hue-rotate:  ;
	--tw-backdrop-invert:  ;
	--tw-backdrop-opacity:  ;
	--tw-backdrop-saturate:  ;
	--tw-backdrop-sepia:  ;
	--tw-contain-size:  ;
	--tw-contain-layout:  ;
	--tw-contain-paint:  ;
	--tw-contain-style:  ;
}
/* http://meyerweb.com/eric/tools/css/reset/
	 v2.0 | 20110126
	 License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	border-radius: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*,
*:before,
*:after {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-decoration-thickness: from-font;
}
img {
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;
}
button {
	cursor: pointer;
	color: inherit;
}
*:not(img) {
	touch-action: manipulation;
}
@media (prefers-reduced-motion: reduce) {
	* {
		animation-play-state: paused !important;
		transition: none !important;
		scroll-behavior: auto !important;
	}
}
.overflow-hidden {
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
}
html {
	height: 100%;
	text-rendering: optimizeSpeed;
	scroll-behavior: smooth;
	font-feature-settings: 'kern' 1;
	/* this should be the smallest possible base font size (i.e. at mobile) */
	font-size: 16px;
}
body {
	height: auto;
	min-height: 100%;
	--tw-bg-opacity: 1;
	background-color: rgba(251, 249, 246, 1);
	background-color: rgba(251, 249, 246, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(51, 51, 51, 1);
	color: rgba(51, 51, 51, var(--tw-text-opacity));
	font-family: var(--font-new-baskerville), Georgia, Cambria, "Times New Roman", Times, serif;
	font-size: 20px;
	line-height: 1.136363636363636;
}
body.italic{
	letter-spacing: 0.005em;
}
a{
	text-decoration-line: none;
}
a:not([class*="text-"]):not([class*="underline"]){
	--tw-text-opacity: 1;
	color: rgba(51, 51, 51, 1);
	color: rgba(51, 51, 51, var(--tw-text-opacity));
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}
a:not([class*="text-"]):not([class*="underline"]):hover{
	--tw-text-opacity: 1;
	color: rgba(37, 130, 216, 1);
	color: rgba(37, 130, 216, var(--tw-text-opacity));
}
a:not([class*="text-"]):not([class*="underline"]) {
	/* @apply text-black hover:text-blue underline underline-offset-[0.25em] decoration-blue/0 hover:decoration-blue transition; */
}
/* a,
button {
	@apply transition duration-150;
} */
a:focus,
button:focus {
	outline: none;
}
/* Style text selection */
::-moz-selection{
	--tw-bg-opacity: 1;
	background-color: rgba(51, 51, 51, 1);
	background-color: rgba(51, 51, 51, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(251, 249, 246, 1);
	color: rgba(251, 249, 246, var(--tw-text-opacity));
}
::selection{
	--tw-bg-opacity: 1;
	background-color: rgba(51, 51, 51, 1);
	background-color: rgba(51, 51, 51, var(--tw-bg-opacity));
	--tw-text-opacity: 1;
	color: rgba(251, 249, 246, 1);
	color: rgba(251, 249, 246, var(--tw-text-opacity));
}
:root {
	--header-height: 48px;

	--gutter: 16px;
	--site-margin: 16px;
	--site-max-width: 1824px;

	--gutter-space-total: calc(11 * var(--gutter));
	--col-space-total: calc(100% - var(--gutter-space-total));
	--col-width: calc(var(--col-space-total) / 12);
}
@media screen and (min-width: 820px) {
:root {
		--gutter: 16px;
		--site-margin: 24px;
}
	}
@media screen and (min-width: 1024px) {
:root {
		--header-height: 68px;
}
	}
@media screen and (min-width: 1512px) {
:root {
		--gutter: 16px;
		--site-margin: 32px;
}
	}
:root{

	--soft-bounce: linear(
		0 0%,
		0.001781 1%,
		0.006992 2%,
		0.015434 3%,
		0.026907 4%,
		0.041209 5%,
		0.058142 6%,
		0.077507 7.000000000000001%,
		0.099107 8%,
		0.122748 9%,
		0.148238 10%,
		0.175391 11%,
		0.204022 12%,
		0.233953 13%,
		0.26501 14.000000000000002%,
		0.297022 15%,
		0.329828 16%,
		0.363269 17%,
		0.397193 18%,
		0.431456 19%,
		0.465918 20%,
		0.500448 21%,
		0.534919 22%,
		0.569214 23%,
		0.603221 24%,
		0.636834 25%,
		0.669956 26%,
		0.702495 27%,
		0.734368 28.000000000000004%,
		0.765498 28.999999999999996%,
		0.795813 30%,
		0.825249 31%,
		0.85375 32%,
		0.881263 33%,
		0.907744 34%,
		0.933153 35%,
		0.957458 36%,
		0.980631 37%,
		1.00265 38%,
		1.023497 39%,
		1.043162 40%,
		1.061637 41%,
		1.07892 42%,
		1.095012 43%,
		1.10992 44%,
		1.123654 45%,
		1.136226 46%,
		1.147654 47%,
		1.157958 48%,
		1.16716 49%,
		1.175287 50%,
		1.182367 51%,
		1.188429 52%,
		1.193506 53%,
		1.197632 54%,
		1.200843 55.00000000000001%,
		1.203175 56.00000000000001%,
		1.204667 56.99999999999999%,
		1.205357 57.99999999999999%,
		1.205287 59%,
		1.204495 60%,
		1.203023 61%,
		1.200912 62%,
		1.198203 63%,
		1.194937 64%,
		1.191155 65%,
		1.186898 66%,
		1.182206 67%,
		1.177118 68%,
		1.171673 69%,
		1.16591 70%,
		1.159864 71%,
		1.153574 72%,
		1.147073 73%,
		1.140395 74%,
		1.133575 75%,
		1.126642 76%,
		1.119629 77%,
		1.112563 78%,
		1.105472 79%,
		1.098383 80%,
		1.091321 81%,
		1.084309 82%,
		1.077369 83%,
		1.070522 84%,
		1.063788 85%,
		1.057183 86%,
		1.050725 87%,
		1.044429 88%,
		1.038308 89%,
		1.032375 90%,
		1.02664 91%,
		1.021114 92%,
		1.015805 93%,
		1.010721 94%,
		1.005866 95%,
		1.001247 96%,
		0.996868 97%,
		0.99273 98%,
		0.988837 99%,
		0.985188 100%
	);
}
.site-wrapper {
	width: 100%;
	max-width: 1824px;
	margin-left: auto;
	margin-right: auto;
}
.site-grid {
	width: 100%;
	max-width: 1824px;
	max-width: var(--site-max-width);
	margin-left: auto;
	margin-right: auto;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-column-gap: 16px;
	grid-column-gap: var(--gutter);
	-moz-column-gap: 16px;
	     column-gap: 16px;
	-moz-column-gap: var(--gutter);
	     column-gap: var(--gutter);
	padding-left: 16px;
	padding-left: var(--site-margin);
	padding-right: 16px;
	padding-right: var(--site-margin);
}
@media (min-width: 712px){
	.site-grid{
		grid-template-columns: repeat(12, minmax(0, 1fr));
	}
}
.top-nav-gap{
	margin-top: 6rem;
}
@media (min-width: 1112px){
	.top-nav-gap{
		margin-top: 8rem;
	}
}
.about-layout .content-block--media{
	margin-top: 6rem;
	margin-bottom: 6rem;
}
@media (min-width: 1112px){
	.about-layout .content-block--media{
		margin-top: 8rem;
		margin-bottom: 8rem;
	}
}
.tickertape__content {
	animation: tickertape 26s linear infinite;
}
.tickertape:hover .tickertape__content {
	animation-play-state: paused;
}
.blockquote{
	position: relative;
	margin-top: 2rem;
	margin-bottom: 2rem;
	font-family: var(--font-new-baskerville), Georgia, Cambria, "Times New Roman", Times, serif;
	font-size: 24px;
	line-height: 1.185185185185185;
}
.blockquote.italic{
	letter-spacing: 0.005em;
}
@media (min-width: 1112px){
	.blockquote{
		margin-top: 3rem;
		margin-bottom: 3rem;
		font-family: var(--font-new-baskerville), Georgia, Cambria, "Times New Roman", Times, serif;
		font-size: 40px;
		line-height: 1.175;
	}
}
.blockquote:before,
	.blockquote::after{
	--tw-text-opacity: 1;
	color: rgba(251, 249, 246, 1);
	color: rgba(251, 249, 246, var(--tw-text-opacity));
		content: '';
		display: inline-block;
		height: 0.3em;
		/* ratio: 0.65186247 */
		width: 0.4em;
		margin-right: 0.2em;
		background-image: url('/svgs/quote.svg');
		background-size: contain;
		transform: translateY(-0.34em);
}
.blockquote::after {
		margin-right: 0;
		margin-left: 0.1em;
		transform: rotate(180deg) translateY(0.34em);
	}
.blockquote.-hide-quotes:before,
		.blockquote.-hide-quotes::after {
			display: none;
		}
@keyframes tickertape {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-100%, 0, 0);
	}
}
@keyframes carouselBullet {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
@keyframes fadeInMoveUpDown {
	0% {
		opacity: 0;
		transform: translateY(2%); /* Starting position */
		animation-timing-function: linear(
		0 0%,
		0.001781 1%,
		0.006992 2%,
		0.015434 3%,
		0.026907 4%,
		0.041209 5%,
		0.058142 6%,
		0.077507 7.000000000000001%,
		0.099107 8%,
		0.122748 9%,
		0.148238 10%,
		0.175391 11%,
		0.204022 12%,
		0.233953 13%,
		0.26501 14.000000000000002%,
		0.297022 15%,
		0.329828 16%,
		0.363269 17%,
		0.397193 18%,
		0.431456 19%,
		0.465918 20%,
		0.500448 21%,
		0.534919 22%,
		0.569214 23%,
		0.603221 24%,
		0.636834 25%,
		0.669956 26%,
		0.702495 27%,
		0.734368 28.000000000000004%,
		0.765498 28.999999999999996%,
		0.795813 30%,
		0.825249 31%,
		0.85375 32%,
		0.881263 33%,
		0.907744 34%,
		0.933153 35%,
		0.957458 36%,
		0.980631 37%,
		1.00265 38%,
		1.023497 39%,
		1.043162 40%,
		1.061637 41%,
		1.07892 42%,
		1.095012 43%,
		1.10992 44%,
		1.123654 45%,
		1.136226 46%,
		1.147654 47%,
		1.157958 48%,
		1.16716 49%,
		1.175287 50%,
		1.182367 51%,
		1.188429 52%,
		1.193506 53%,
		1.197632 54%,
		1.200843 55.00000000000001%,
		1.203175 56.00000000000001%,
		1.204667 56.99999999999999%,
		1.205357 57.99999999999999%,
		1.205287 59%,
		1.204495 60%,
		1.203023 61%,
		1.200912 62%,
		1.198203 63%,
		1.194937 64%,
		1.191155 65%,
		1.186898 66%,
		1.182206 67%,
		1.177118 68%,
		1.171673 69%,
		1.16591 70%,
		1.159864 71%,
		1.153574 72%,
		1.147073 73%,
		1.140395 74%,
		1.133575 75%,
		1.126642 76%,
		1.119629 77%,
		1.112563 78%,
		1.105472 79%,
		1.098383 80%,
		1.091321 81%,
		1.084309 82%,
		1.077369 83%,
		1.070522 84%,
		1.063788 85%,
		1.057183 86%,
		1.050725 87%,
		1.044429 88%,
		1.038308 89%,
		1.032375 90%,
		1.02664 91%,
		1.021114 92%,
		1.015805 93%,
		1.010721 94%,
		1.005866 95%,
		1.001247 96%,
		0.996868 97%,
		0.99273 98%,
		0.988837 99%,
		0.985188 100%
	);
		animation-timing-function: var(--soft-bounce);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
		animation-timing-function: linear(
		0 0%,
		0.001781 1%,
		0.006992 2%,
		0.015434 3%,
		0.026907 4%,
		0.041209 5%,
		0.058142 6%,
		0.077507 7.000000000000001%,
		0.099107 8%,
		0.122748 9%,
		0.148238 10%,
		0.175391 11%,
		0.204022 12%,
		0.233953 13%,
		0.26501 14.000000000000002%,
		0.297022 15%,
		0.329828 16%,
		0.363269 17%,
		0.397193 18%,
		0.431456 19%,
		0.465918 20%,
		0.500448 21%,
		0.534919 22%,
		0.569214 23%,
		0.603221 24%,
		0.636834 25%,
		0.669956 26%,
		0.702495 27%,
		0.734368 28.000000000000004%,
		0.765498 28.999999999999996%,
		0.795813 30%,
		0.825249 31%,
		0.85375 32%,
		0.881263 33%,
		0.907744 34%,
		0.933153 35%,
		0.957458 36%,
		0.980631 37%,
		1.00265 38%,
		1.023497 39%,
		1.043162 40%,
		1.061637 41%,
		1.07892 42%,
		1.095012 43%,
		1.10992 44%,
		1.123654 45%,
		1.136226 46%,
		1.147654 47%,
		1.157958 48%,
		1.16716 49%,
		1.175287 50%,
		1.182367 51%,
		1.188429 52%,
		1.193506 53%,
		1.197632 54%,
		1.200843 55.00000000000001%,
		1.203175 56.00000000000001%,
		1.204667 56.99999999999999%,
		1.205357 57.99999999999999%,
		1.205287 59%,
		1.204495 60%,
		1.203023 61%,
		1.200912 62%,
		1.198203 63%,
		1.194937 64%,
		1.191155 65%,
		1.186898 66%,
		1.182206 67%,
		1.177118 68%,
		1.171673 69%,
		1.16591 70%,
		1.159864 71%,
		1.153574 72%,
		1.147073 73%,
		1.140395 74%,
		1.133575 75%,
		1.126642 76%,
		1.119629 77%,
		1.112563 78%,
		1.105472 79%,
		1.098383 80%,
		1.091321 81%,
		1.084309 82%,
		1.077369 83%,
		1.070522 84%,
		1.063788 85%,
		1.057183 86%,
		1.050725 87%,
		1.044429 88%,
		1.038308 89%,
		1.032375 90%,
		1.02664 91%,
		1.021114 92%,
		1.015805 93%,
		1.010721 94%,
		1.005866 95%,
		1.001247 96%,
		0.996868 97%,
		0.99273 98%,
		0.988837 99%,
		0.985188 100%
	);
		animation-timing-function: var(--soft-bounce);
	}
}
.intro--logo {
	transform: translateY(2%); /* Start slightly below its final position */
	animation: fadeInMoveUpDown 1.3s forwards;
}
.dots.autoplaying .dot.selected::before {
	animation: carouselBullet 4000ms linear 1;
	animation-fill-mode: both;
}
.content-block--info-box + .content-block--info-box{
	margin-top: -2rem;
}
mux-player {
	--controls: none;

	--dialog: none;

	--loading-indicator: none;

	--play-button: none;
	--live-button: none;
	--seek-backward-button: none;
	--seek-forward-button: none;
	--mute-button: none;
	--captions-button: none;
	--airplay-button: none;
	--pip-button: none;
	--fullscreen-button: none;
	--cast-button: none;
	--playback-rate-button: none;
	--volume-range: none;
	--time-range: none;
	--time-display: none;
	--duration-display: none;
	--rendition-selectmenu: none;

	--center-controls: none;
	--bottom-play-button: none;
}
.container{
	width: 100%;
}
@media (min-width: 390px){
	.container{
		max-width: 390px;
	}
}
@media (min-width: 712px){
	.container{
		max-width: 712px;
	}
}
@media (min-width: 1112px){
	.container{
		max-width: 1112px;
	}
}
@media (min-width: 1342px){
	.container{
		max-width: 1342px;
	}
}
@media (min-width: 1512px){
	.container{
		max-width: 1512px;
	}
}
.home-page-collage {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(24, 1fr);
    grid-gap: 1rem 1rem;
    gap: 1rem 1rem;
  }
@media (orientation: portrait) and (max-width: 767px) {
    .home-page-collage {
      display: grid;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(24, 1fr);
      grid-gap: 1rem 1rem;
      gap: 1rem 1rem;
    }
  }
.pointer-events-none{
	pointer-events: none;
}
.visible{
	visibility: visible;
}
.invisible{
	visibility: hidden;
}
.fixed{
	position: fixed;
}
.absolute{
	position: absolute;
}
.relative{
	position: relative;
}
.sticky{
	position: sticky;
}
.inset-0{
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
.bottom-0{
	bottom: 0px;
}
.bottom-2{
	bottom: 0.5rem;
}
.bottom-4{
	bottom: 1rem;
}
.bottom-8{
	bottom: 2rem;
}
.bottom-\[-2px\]{
	bottom: -2px;
}
.left-0{
	left: 0px;
}
.left-2{
	left: 0.5rem;
}
.left-4{
	left: 1rem;
}
.left-\[-2px\]{
	left: -2px;
}
.right-0{
	right: 0px;
}
.right-\[-2px\]{
	right: -2px;
}
.top-0{
	top: 0px;
}
.top-1\/2{
	top: 50%;
}
.top-4{
	top: 1rem;
}
.top-\[-0\.1em\]{
	top: -0.1em;
}
.top-\[-2px\]{
	top: -2px;
}
.top-\[0\.04em\]{
	top: 0.04em;
}
.top-\[0\.2em\]{
	top: 0.2em;
}
.top-\[calc\(var\(--header-height\)_\+_8rem\)\]{
	top: calc(48px + 8rem);
	top: calc(var(--header-height) + 8rem);
}
.z-0{
	z-index: 0;
}
.z-100{
	z-index: 100;
}
.z-200{
	z-index: 200;
}
.z-800{
	z-index: 800;
}
.z-900{
	z-index: 900;
}
.z-\[999999\]{
	z-index: 999999;
}
.z-\[99999\]{
	z-index: 99999;
}
.z-\[9999\]{
	z-index: 9999;
}
.col-span-1{
	grid-column: span 1 / span 1;
}
.col-span-10{
	grid-column: span 10 / span 10;
}
.col-span-12{
	grid-column: span 12 / span 12;
}
.col-span-2{
	grid-column: span 2 / span 2;
}
.col-span-4{
	grid-column: span 4 / span 4;
}
.col-span-5{
	grid-column: span 5 / span 5;
}
.col-span-full{
	grid-column: 1 / -1;
}
.col-start-1{
	grid-column-start: 1;
}
.col-start-2{
	grid-column-start: 2;
}
.col-start-9{
	grid-column-start: 9;
}
.mx-\[1px\]{
	margin-left: 1px;
	margin-right: 1px;
}
.mx-auto{
	margin-left: auto;
	margin-right: auto;
}
.my-12{
	margin-top: 3rem;
	margin-bottom: 3rem;
}
.my-16{
	margin-top: 4rem;
	margin-bottom: 4rem;
}
.my-4{
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.my-40{
	margin-top: 10rem;
	margin-bottom: 10rem;
}
.my-\[10rem\]{
	margin-top: 10rem;
	margin-bottom: 10rem;
}
.my-\[12rem\]{
	margin-top: 12rem;
	margin-bottom: 12rem;
}
.my-\[4rem\]{
	margin-top: 4rem;
	margin-bottom: 4rem;
}
.my-\[6rem\]{
	margin-top: 6rem;
	margin-bottom: 6rem;
}
.my-\[8rem\]{
	margin-top: 8rem;
	margin-bottom: 8rem;
}
.\!mb-0{
	margin-bottom: 0px !important;
}
.\!mt-0{
	margin-top: 0px !important;
}
.-mt-\[2px\]{
	margin-top: -2px;
}
.mb-1{
	margin-bottom: 0.25rem;
}
.mb-12{
	margin-bottom: 3rem;
}
.mb-18{
	margin-bottom: 4.5rem;
}
.mb-2{
	margin-bottom: 0.5rem;
}
.mb-4{
	margin-bottom: 1rem;
}
.mb-5{
	margin-bottom: 1.25rem;
}
.mb-6{
	margin-bottom: 1.5rem;
}
.mb-8{
	margin-bottom: 2rem;
}
.mb-\[10rem\]{
	margin-bottom: 10rem;
}
.mb-\[25svh\]{
	margin-bottom: 25svh;
}
.mb-\[2rem\]{
	margin-bottom: 2rem;
}
.mb-\[3rem\]{
	margin-bottom: 3rem;
}
.mb-\[4rem\]{
	margin-bottom: 4rem;
}
.mb-\[6rem\]{
	margin-bottom: 6rem;
}
.mb-\[8rem\]{
	margin-bottom: 8rem;
}
.ml-2{
	margin-left: 0.5rem;
}
.mt-10{
	margin-top: 2.5rem;
}
.mt-12{
	margin-top: 3rem;
}
.mt-3{
	margin-top: 0.75rem;
}
.mt-32{
	margin-top: 8rem;
}
.mt-4{
	margin-top: 1rem;
}
.mt-5{
	margin-top: 1.25rem;
}
.mt-6{
	margin-top: 1.5rem;
}
.mt-8{
	margin-top: 2rem;
}
.mt-\[-0\.1em\]{
	margin-top: -0.1em;
}
.mt-\[0\.2em\]{
	margin-top: 0.2em;
}
.mt-\[1px\]{
	margin-top: 1px;
}
.mt-\[25vh\]{
	margin-top: 25vh;
}
.mt-\[3rem\]{
	margin-top: 3rem;
}
.mt-\[4rem\]{
	margin-top: 4rem;
}
.mt-\[6rem\]{
	margin-top: 6rem;
}
.mt-\[8rem\]{
	margin-top: 8rem;
}
.block{
	display: block;
}
.inline-block{
	display: inline-block;
}
.flex{
	display: flex;
}
.inline-flex{
	display: inline-flex;
}
.grid{
	display: grid;
}
.hidden{
	display: none;
}
.aspect-\[16\/10\]{
	aspect-ratio: 16/10;
}
.aspect-\[3\/2\]{
	aspect-ratio: 3/2;
}
.aspect-\[4\/5\]{
	aspect-ratio: 4/5;
}
.aspect-square{
	aspect-ratio: 1 / 1;
}
.aspect-video{
	aspect-ratio: 16 / 9;
}
.size-\[1px\]{
	width: 1px;
	height: 1px;
}
.h-0{
	height: 0px;
}
.h-10{
	height: 2.5rem;
}
.h-28{
	height: 7rem;
}
.h-3{
	height: 0.75rem;
}
.h-4{
	height: 1rem;
}
.h-5{
	height: 1.25rem;
}
.h-8{
	height: 2rem;
}
.h-\[0\.8em\]{
	height: 0.8em;
}
.h-\[100lvh\]{
	height: 100lvh;
}
.h-\[12px\]{
	height: 12px;
}
.h-\[200lvh\]{
	height: 200lvh;
}
.h-\[24px\]{
	height: 24px;
}
.h-\[28px\]{
	height: 28px;
}
.h-\[2px\]{
	height: 2px;
}
.h-\[4px\]{
	height: 4px;
}
.h-\[calc\(100vh-var\(--header-height\)-16rem\)\]{
	height: calc(100vh - 48px - 16rem);
	height: calc(100vh - var(--header-height) - 16rem);
}
.h-auto{
	height: auto;
}
.h-full{
	height: 100%;
}
.h-intro{
	height: 80vh;
	height: 80svh;
}
.h-screen{
	height: 100vh;
	height: var(--app-height);
	height: 100svh;
}
.max-h-full{
	max-height: 100%;
}
.min-h-\[100lvh\]{
	min-height: 100lvh;
}
.min-h-\[300lvh\]{
	min-height: 300lvh;
}
.min-h-screen{
	min-height: 100vh;
	min-height: var(--app-height);
	min-height: 100svh;
}
.min-h-screen-less-header-height{
	min-height: calc(100vh - 48px);
	min-height: calc(100vh - var(--header-height));
	min-height: calc(100svh - 48px);
	min-height: calc(100svh - var(--header-height));
}
.w-1\/2{
	width: 50%;
}
.w-1\/3{
	width: 33.333333%;
}
.w-1\/4{
	width: 25%;
}
.w-10{
	width: 2.5rem;
}
.w-12{
	width: 3rem;
}
.w-2\/5{
	width: 40%;
}
.w-3{
	width: 0.75rem;
}
.w-4{
	width: 1rem;
}
.w-5{
	width: 1.25rem;
}
.w-7{
	width: 1.75rem;
}
.w-8{
	width: 2rem;
}
.w-\[0\.8em\]{
	width: 0.8em;
}
.w-\[12px\]{
	width: 12px;
}
.w-\[24px\]{
	width: 24px;
}
.w-\[28px\]{
	width: 28px;
}
.w-\[2px\]{
	width: 2px;
}
.w-auto{
	width: auto;
}
.w-full{
	width: 100%;
}
.max-w-\[1824px\]{
	max-width: 1824px;
}
.max-w-\[60vw\]{
	max-width: 60vw;
}
.flex-none{
	flex: none;
}
.flex-grow{
	flex-grow: 1;
}
.flex-grow-0{
	flex-grow: 0;
}
.-translate-y-1\/2{
	--tw-translate-y: -50%;
	transform: translate(var(--tw-translate-x), -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-\[0\.05em\]{
	--tw-translate-y: -0.05em;
	transform: translate(var(--tw-translate-x), -0.05em) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-\[0\.08em\]{
	--tw-translate-y: -0.08em;
	transform: translate(var(--tw-translate-x), -0.08em) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-full{
	--tw-translate-y: -100%;
	transform: translate(var(--tw-translate-x), -100%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[10\%\]{
	--tw-translate-x: 10%;
	transform: translate(10%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0{
	--tw-translate-y: 0px;
	transform: translate(var(--tw-translate-x), 0px) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[0\.25em\]{
	--tw-translate-y: 0.25em;
	transform: translate(var(--tw-translate-x), 0.25em) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[100\%\]{
	--tw-translate-y: 100%;
	transform: translate(var(--tw-translate-x), 100%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-0{
	--tw-rotate: 0deg;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(0deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180{
	--tw-rotate: 180deg;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(180deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90{
	--tw-rotate: 90deg;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(90deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-50{
	--tw-scale-x: .5;
	--tw-scale-y: .5;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(.5) scaleY(.5);
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform{
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes fadeIn{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
.animate-fade-in{
	animation: fadeIn 1s linear 1;
}
@keyframes loadscreenLogo{
	0%{
		transform: translateY(-50%);
		top: 50%;
		opacity: 0;
	}
	50%{
		transform: translateY(-50%);
		top: 50%;
		opacity: 1;
	}
	100%{
		transform: translateY(0%);
		top: 0;
		opacity: 1;
	}
}
.animate-loadscreen-logo{
	animation: loadscreenLogo 3s cubic-bezier(0.65, 0, 0.35, 1) 1;
}
@keyframes pulse{
	50%{
		opacity: .5;
	}
}
.animate-pulse{
	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.cursor-grab{
	cursor: grab;
}
.cursor-not-allowed{
	cursor: not-allowed;
}
.cursor-pointer{
	cursor: pointer;
}
.resize{
	resize: both;
}
.appearance-none{
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
}
.auto-rows-auto{
	grid-auto-rows: auto;
}
.grid-cols-1{
	grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-12{
	grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-2{
	grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3{
	grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-rows-\[0fr\]{
	grid-template-rows: 0fr;
}
.grid-rows-\[1fr\]{
	grid-template-rows: 1fr;
}
.flex-col{
	flex-direction: column;
}
.flex-wrap{
	flex-wrap: wrap;
}
.items-start{
	align-items: flex-start;
}
.items-end{
	align-items: flex-end;
}
.items-center{
	align-items: center;
}
.items-baseline{
	align-items: baseline;
}
.justify-start{
	justify-content: flex-start;
}
.justify-end{
	justify-content: flex-end;
}
.justify-center{
	justify-content: center;
}
.justify-between{
	justify-content: space-between;
}
.gap-11{
	gap: 2.75rem;
}
.gap-14{
	gap: 3.5rem;
}
.gap-2{
	gap: 0.5rem;
}
.gap-3{
	gap: 0.75rem;
}
.gap-4{
	gap: 1rem;
}
.gap-6{
	gap: 1.5rem;
}
.gap-8{
	gap: 2rem;
}
.gap-gutter{
	gap: 16px;
	gap: var(--gutter);
}
.gap-x-4{
	-moz-column-gap: 1rem;
	     column-gap: 1rem;
}
.gap-x-gutter{
	-moz-column-gap: 16px;
	     column-gap: 16px;
	-moz-column-gap: var(--gutter);
	     column-gap: var(--gutter);
}
.gap-y-12{
	row-gap: 3rem;
}
.gap-y-18{
	row-gap: 4.5rem;
}
.gap-y-8{
	row-gap: 2rem;
}
.gap-y-\[3rem\]{
	row-gap: 3rem;
}
.space-y-8 > :not([hidden]) ~ :not([hidden]){
	--tw-space-y-reverse: 0;
	margin-top: calc(2rem * (1 - 0));
	margin-top: calc(2rem * (1 - var(--tw-space-y-reverse)));
	margin-top: calc(2rem * calc(1 - 0));
	margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(2rem * 0);
	margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.overflow-hidden{
	overflow: hidden;
}
.overflow-clip{
	overflow: clip;
}
.overflow-x-auto{
	overflow-x: auto;
}
.overflow-x-clip{
	overflow-x: clip;
}
.text-balance{
	text-wrap: balance;
}
.rounded-\[2px\]{
	border-radius: 2px;
}
.rounded-full{
	border-radius: 9999px;
}
.border{
	border-width: 1px;
}
.border-\[1rem\]{
	border-width: 1rem;
}
.border-b{
	border-bottom-width: 1px;
}
.border-t{
	border-top-width: 1px;
}
.border-t-0{
	border-top-width: 0px;
}
.border-solid{
	border-style: solid;
}
.border-black{
	--tw-border-opacity: 1;
	border-color: rgba(51, 51, 51, 1);
	border-color: rgba(51, 51, 51, var(--tw-border-opacity));
}
.border-black\/20{
	border-color: rgba(51, 51, 51, 0.2);
}
.border-black\/50{
	border-color: rgba(51, 51, 51, 0.5);
}
.border-error{
	--tw-border-opacity: 1;
	border-color: rgba(231, 76, 60, 1);
	border-color: rgba(231, 76, 60, var(--tw-border-opacity));
}
.border-success{
	--tw-border-opacity: 1;
	border-color: rgba(46, 204, 113, 1);
	border-color: rgba(46, 204, 113, var(--tw-border-opacity));
}
.border-transparent{
	border-color: transparent;
}
.border-white{
	--tw-border-opacity: 1;
	border-color: rgba(251, 249, 246, 1);
	border-color: rgba(251, 249, 246, var(--tw-border-opacity));
}
.bg-black{
	--tw-bg-opacity: 1;
	background-color: rgba(51, 51, 51, 1);
	background-color: rgba(51, 51, 51, var(--tw-bg-opacity));
}
.bg-blackBg{
	--tw-bg-opacity: 1;
	background-color: rgba(37, 37, 37, 1);
	background-color: rgba(37, 37, 37, var(--tw-bg-opacity));
}
.bg-current{
	background-color: currentColor;
}
.bg-error{
	--tw-bg-opacity: 1;
	background-color: rgba(231, 76, 60, 1);
	background-color: rgba(231, 76, 60, var(--tw-bg-opacity));
}
.bg-success{
	--tw-bg-opacity: 1;
	background-color: rgba(46, 204, 113, 1);
	background-color: rgba(46, 204, 113, var(--tw-bg-opacity));
}
.bg-white{
	--tw-bg-opacity: 1;
	background-color: rgba(251, 249, 246, 1);
	background-color: rgba(251, 249, 246, var(--tw-bg-opacity));
}
.bg-white\/50{
	background-color: rgba(251, 249, 246, 0.5);
}
.bg-white\/80{
	background-color: rgba(251, 249, 246, 0.8);
}
.bg-white\/90{
	background-color: rgba(251, 249, 246, 0.9);
}
.fill-current{
	fill: currentColor;
}
.stroke-black{
	stroke: #333333;
}
.stroke-black\/20{
	stroke: rgba(51, 51, 51, 0.2);
}
.object-contain{
	-o-object-fit: contain;
	   object-fit: contain;
}
.object-cover{
	-o-object-fit: cover;
	   object-fit: cover;
}
.object-top{
	-o-object-position: top;
	   object-position: top;
}
.p-2{
	padding: 0.5rem;
}
.p-4{
	padding: 1rem;
}
.p-6{
	padding: 1.5rem;
}
.px-4{
	padding-left: 1rem;
	padding-right: 1rem;
}
.px-5{
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}
.px-8{
	padding-left: 2rem;
	padding-right: 2rem;
}
.px-\[12px\]{
	padding-left: 12px;
	padding-right: 12px;
}
.px-\[8px\]{
	padding-left: 8px;
	padding-right: 8px;
}
.px-site-margin{
	padding-left: 16px;
	padding-left: var(--site-margin);
	padding-right: 16px;
	padding-right: var(--site-margin);
}
.py-11{
	padding-top: 2.75rem;
	padding-bottom: 2.75rem;
}
.py-16{
	padding-top: 4rem;
	padding-bottom: 4rem;
}
.py-2{
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.py-36{
	padding-top: 9rem;
	padding-bottom: 9rem;
}
.py-4{
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.py-7{
	padding-top: 1.75rem;
	padding-bottom: 1.75rem;
}
.py-8{
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.py-\[3px\]{
	padding-top: 3px;
	padding-bottom: 3px;
}
.py-\[5px\]{
	padding-top: 5px;
	padding-bottom: 5px;
}
.pb-3{
	padding-bottom: 0.75rem;
}
.pb-\[2px\]{
	padding-bottom: 2px;
}
.pb-\[4px\]{
	padding-bottom: 4px;
}
.pb-\[6px\]{
	padding-bottom: 6px;
}
.pb-\[70vh\]{
	padding-bottom: 70vh;
}
.pl-10{
	padding-left: 2.5rem;
}
.pl-12{
	padding-left: 3rem;
}
.pl-6{
	padding-left: 1.5rem;
}
.pl-7{
	padding-left: 1.75rem;
}
.pr-1{
	padding-right: 0.25rem;
}
.pr-16{
	padding-right: 4rem;
}
.pr-6{
	padding-right: 1.5rem;
}
.pr-\[0\.4em\]{
	padding-right: 0.4em;
}
.pt-16{
	padding-top: 4rem;
}
.pt-2{
	padding-top: 0.5rem;
}
.pt-4{
	padding-top: 1rem;
}
.pt-6{
	padding-top: 1.5rem;
}
.pt-\[0\.1em\]{
	padding-top: 0.1em;
}
.pt-\[1rem\]{
	padding-top: 1rem;
}
.pt-\[4px\]{
	padding-top: 4px;
}
.pt-header-height{
	padding-top: 48px;
	padding-top: var(--header-height);
}
.text-left{
	text-align: left;
}
.text-center{
	text-align: center;
}
.text-right{
	text-align: right;
}
.align-top{
	vertical-align: top;
}
.font-display{
	font-family: --font-temp), Georgia, Cambria, "Times New Roman", Times, serif;
}
.font-new-sans{
	font-family: var(--font-untitled), "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.font-serif{
	font-family: var(--font-temp), Georgia, Cambria, "Times New Roman", Times, serif;
}
.text-\[0\.4em\]{
	font-size: 0.4em;
}
.text-\[10px\]{
	font-size: 10px;
}
.text-\[14px\]{
	font-size: 14px;
}
.text-\[28px\]{
	font-size: 28px;
}
.font-500{
	font-weight: 500;
}
.uppercase{
	text-transform: uppercase;
}
.italic{
	font-style: italic;
}
.\!leading-\[0\.8\]{
	line-height: 0.8 !important;
}
.leading-100{
	line-height: 1;
}
.leading-120{
	line-height: 1.2;
}
.leading-140{
	line-height: 1.4;
}
.leading-90{
	line-height: 0.9;
}
.leading-\[1\.166666666666667\]{
	line-height: 1.166666666666667;
}
.leading-\[1\.7\]{
	line-height: 1.7;
}
.tracking-130{
	letter-spacing: 0.13em;
}
.tracking-\[0\.0325em\]{
	letter-spacing: 0.0325em;
}
.text-black{
	--tw-text-opacity: 1;
	color: rgba(51, 51, 51, 1);
	color: rgba(51, 51, 51, var(--tw-text-opacity));
}
.text-current{
	color: currentColor;
}
.text-error{
	--tw-text-opacity: 1;
	color: rgba(231, 76, 60, 1);
	color: rgba(231, 76, 60, var(--tw-text-opacity));
}
.text-white{
	--tw-text-opacity: 1;
	color: rgba(251, 249, 246, 1);
	color: rgba(251, 249, 246, var(--tw-text-opacity));
}
.underline{
	text-decoration-line: underline;
}
.underline-offset-2{
	text-underline-offset: 2px;
}
.underline-offset-4{
	text-underline-offset: 4px;
}
.underline-offset-\[0\.25em\]{
	text-underline-offset: 0.25em;
}
.opacity-0{
	opacity: 0;
}
.opacity-100{
	opacity: 1;
}
.opacity-30{
	opacity: 0.3;
}
.opacity-40{
	opacity: 0.4;
}
.opacity-50{
	opacity: 0.5;
}
.opacity-80{
	opacity: 0.8;
}
.shadow{
	--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
	--tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
	box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.blur{
	--tw-blur: blur(8px);
	filter: blur(8px) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale{
	--tw-grayscale: grayscale(100%);
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) grayscale(100%) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.\!filter{
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter{
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-\[6px\]{
	--tw-backdrop-blur: blur(6px);
	-webkit-backdrop-filter: blur(6px) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	        backdrop-filter: blur(6px) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	-webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition{
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}
.transition-all{
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}
.transition-opacity{
	transition-property: opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}
.transition-transform{
	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}
.delay-\[2s\]{
	transition-delay: 2s;
}
.duration-1000{
	transition-duration: 1000ms;
}
.duration-150{
	transition-duration: 150ms;
}
.duration-300{
	transition-duration: 300ms;
}
.duration-500{
	transition-duration: 500ms;
}
.duration-\[400ms\]{
	transition-duration: 400ms;
}
.ease-in-out{
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.font-display,
	.font-serif,
	.font-sans {
		color: #f0f !important;
	}
.underline {
		-webkit-text-decoration-skip: ink;
		        text-decoration-skip-ink: auto;
	}
/* .font-display {
		font-variant-ligatures: common-ligatures;
	}
	.text-small {
		font-size: 0.75rem;
	}
	.text-menu {
		@apply text-[1.4rem] md:text-[1.5rem];
		line-height: 1;
	}
	.text-body {
		font-size: 1.125rem;
		line-height: 1.278;
	}
	.text-body--large {
		font-size: 1.375rem;
		line-height: 1.273;
	}
	.text-h2 {
		font-size: 2.813rem;
	}
	.text-h3 {
		font-size: 1.125rem;
	}
	.text-h4 {
		font-size: 1.5rem;
	}
	.text-pullquote {
		font-size: fluid(32px, 42px);
	}
	.text-page-header {
		font-size: fluid(52px, 68px);
		@apply font-display leading-115 md:leading-110 text-balance;
	}
	.large-fluid-type {
		@apply font-display text-[10vw] leading-115 md:leading-110 text-balance [&_em]:italic;
	} */
.style-h1{
	font-family: var(--font-baskerville-display), Georgia, Cambria, "Times New Roman", Times, serif;
	font-size: 4.25rem;
	line-height: 1.4;
	letter-spacing: -0.01em;
		text-wrap: balance;
}
@media (min-width: 712px){
	.style-h1{
		font-size: min(10vw,148px);
	}
}
.style-h1 {
		font-feature-settings: "liga", "clig";
		font-variant-ligatures: common-ligatures;
	}
.style-h1.page-header{
	line-height: 0.7em;
}
.style-h2{
	font-family: var(--font-baskerville-display), Georgia, Cambria, "Times New Roman", Times, serif;
	font-size: 46px;
	line-height: 1.08695652173913;
	letter-spacing: -0.01em;
}
@media (min-width: 1112px){
	.style-h2{
		font-size: 68px;
		line-height: 1.088235294117647;
	}
}
.style-h2 {
		font-feature-settings: "liga", "clig";
		font-variant-ligatures: common-ligatures;
	}
.style-h2.italic{
	letter-spacing: 0em;
}
.style-h3{
	font-family: var(--font-untitled), "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 18px;
	text-transform: uppercase;
	line-height: 1.111111111111111;
	letter-spacing: 0.10em;
}
.style-h4{
	font-family: var(--font-untitled), "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 20px;
	line-height: 1.2;
}
@media (min-width: 1112px){
	.style-h4{
		font-size: 24px;
		line-height: 1.25;
	}
}
.style-body--xl{
	font-family: var(--font-new-baskerville), Georgia, Cambria, "Times New Roman", Times, serif;
	font-size: 40px;
	line-height: 1.175;
}
.style-body--lg{
	font-family: var(--font-new-baskerville), Georgia, Cambria, "Times New Roman", Times, serif;
	font-size: 24px;
	line-height: 1.185185185185185;
}
.style-body--lg.italic{
	letter-spacing: 0.005em;
}
.style-body{
	font-family: var(--font-new-baskerville), Georgia, Cambria, "Times New Roman", Times, serif;
	font-size: 20px;
	line-height: 1.136363636363636;
}
.style-body.italic{
	letter-spacing: 0.005em;
}
.style-nav-item{
	font-family: var(--font-untitled), "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 20px;
	line-height: 1.2;
}
@media (min-width: 1112px){
	.style-nav-item{
		font-size: 22px;
		line-height: 1.25;
	}
}
.style-caption{
	font-family: var(--font-untitled), "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1.166666666666667;
}
@media (min-width: 712px){
	.style-caption{
		font-size: 18px;
	}
}
.style-caption-small{
	font-family: var(--font-untitled), "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1.166666666666667;
}
.style-tag{
	font-family: var(--font-untitled), "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1;
	letter-spacing: 0.005em;
}
.rounded-weak{
	border-radius: 4px;
}
.text-balance {
		text-wrap: balance;
	}
.inset-center {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
.collage-item-span-1 { grid-column: span 1 / span 1; }
.collage-item-span-2 { grid-column: span 2 / span 2; }
.collage-item-span-3 { grid-column: span 3 / span 3; }
.collage-item-span-4 { grid-column: span 4 / span 4; }
.collage-item-span-5 { grid-column: span 5 / span 5; }
.collage-item-span-6 { grid-column: span 6 / span 6; }
.collage-item-span-7 { grid-column: span 7 / span 7; }
.collage-item-span-8 { grid-column: span 8 / span 8; }
.collage-item-span-9 { grid-column: span 9 / span 9; }
.collage-item-span-10 { grid-column: span 10 / span 10; }
.collage-item-span-11 { grid-column: span 11 / span 11; }
.collage-item-span-12 { grid-column: span 12 / span 12; }
.collage-item-span-13 { grid-column: span 13 / span 13; }
.collage-item-span-14 { grid-column: span 14 / span 14; }
.collage-item-span-15 { grid-column: span 15 / span 15; }
.collage-item-span-16 { grid-column: span 16 / span 16; }
.collage-item-span-17 { grid-column: span 17 / span 17; }
.collage-item-span-18 { grid-column: span 18 / span 18; }
.collage-item-span-19 { grid-column: span 19 / span 19; }
.collage-item-span-20 { grid-column: span 20 / span 20; }
.collage-item-span-21 { grid-column: span 21 / span 21; }
.collage-item-span-22 { grid-column: span 22 / span 22; }
.collage-item-span-23 { grid-column: span 23 / span 23; }
.collage-item-span-24 { grid-column: span 24 / span 24; }
.collage-item-col-start-1 { grid-column-start: 1; }
.collage-item-col-start-2 { grid-column-start: 2; }
.collage-item-col-start-3 { grid-column-start: 3; }
.collage-item-col-start-4 { grid-column-start: 4; }
.collage-item-col-start-5 { grid-column-start: 5; }
.collage-item-col-start-6 { grid-column-start: 6; }
.collage-item-col-start-7 { grid-column-start: 7; }
.collage-item-col-start-8 { grid-column-start: 8; }
.collage-item-col-start-9 { grid-column-start: 9; }
.collage-item-col-start-10 { grid-column-start: 10; }
.collage-item-col-start-11 { grid-column-start: 11; }
.collage-item-col-start-12 { grid-column-start: 12; }
.collage-item-col-start-13 { grid-column-start: 13; }
.collage-item-col-start-14 { grid-column-start: 14; }
.collage-item-col-start-15 { grid-column-start: 15; }
.collage-item-col-start-16 { grid-column-start: 16; }
.collage-item-col-start-17 { grid-column-start: 17; }
.collage-item-col-start-18 { grid-column-start: 18; }
.collage-item-col-start-19 { grid-column-start: 19; }
.collage-item-col-start-20 { grid-column-start: 20; }
.collage-item-col-start-21 { grid-column-start: 21; }
.collage-item-col-start-22 { grid-column-start: 22; }
.collage-item-col-start-23 { grid-column-start: 23; }
.collage-item-col-start-24 { grid-column-start: 24; }
.collage-item-row-start-1 { grid-row-start: 1; }
.collage-item-row-start-2 { grid-row-start: 2; }
.collage-item-row-start-3 { grid-row-start: 3; }
.collage-item-row-start-4 { grid-row-start: 4; }
.collage-item-row-start-5 { grid-row-start: 5; }
.collage-item-row-start-6 { grid-row-start: 6; }
.collage-item-row-start-7 { grid-row-start: 7; }
.collage-item-row-start-8 { grid-row-start: 8; }
.collage-item-row-start-9 { grid-row-start: 9; }
.collage-item-row-start-10 { grid-row-start: 10; }
.collage-item-row-start-11 { grid-row-start: 11; }
.collage-item-row-start-12 { grid-row-start: 12; }
.collage-item-row-start-13 { grid-row-start: 13; }
.collage-item-row-start-14 { grid-row-start: 14; }
.collage-item-row-start-15 { grid-row-start: 15; }
.collage-item-row-start-16 { grid-row-start: 16; }
.collage-item-row-start-17 { grid-row-start: 17; }
.collage-item-row-start-18 { grid-row-start: 18; }
.collage-item-row-start-19 { grid-row-start: 19; }
.collage-item-row-start-20 { grid-row-start: 20; }
.collage-item-row-start-21 { grid-row-start: 21; }
.collage-item-row-start-22 { grid-row-start: 22; }
.collage-item-row-start-23 { grid-row-start: 23; }
.collage-item-row-start-24 { grid-row-start: 24; }
.before\:absolute::before{
	content: var(--tw-content);
	position: absolute;
}
.before\:left-0::before{
	content: var(--tw-content);
	left: 0px;
}
.before\:block::before{
	content: var(--tw-content);
	display: block;
}
.before\:h-\[1px\]::before{
	content: var(--tw-content);
	height: 1px;
}
.before\:h-\[4px\]::before{
	content: var(--tw-content);
	height: 4px;
}
.before\:w-full::before{
	content: var(--tw-content);
	width: 100%;
}
.before\:bg-black::before{
	content: var(--tw-content);
	--tw-bg-opacity: 1;
	background-color: rgba(51, 51, 51, 1);
	background-color: rgba(51, 51, 51, var(--tw-bg-opacity));
}
.before\:bg-white::before{
	content: var(--tw-content);
	--tw-bg-opacity: 1;
	background-color: rgba(251, 249, 246, 1);
	background-color: rgba(251, 249, 246, var(--tw-bg-opacity));
}
.before\:opacity-0::before{
	content: var(--tw-content);
	opacity: 0;
}
.before\:opacity-100::before{
	content: var(--tw-content);
	opacity: 1;
}
.before\:content-\[\'\#\'\]::before{
	--tw-content: '#';
	content: '#';
	content: var(--tw-content);
}
.before\:content-\[\'\'\]::before{
	--tw-content: '';
	content: '';
	content: var(--tw-content);
}
.before\:content-\[\'\2014\'\]::before{
	--tw-content: '—';
	content: '—';
	content: var(--tw-content);
}
.after\:content-\[close-quote\]::after{
	--tw-content: close-quote;
	content: close-quote;
	content: var(--tw-content);
}
.first\:border-t:first-child{
	border-top-width: 1px;
}
.last\:mb-0:last-child{
	margin-bottom: 0px;
}
.hover\:border-blue:hover{
	--tw-border-opacity: 1;
	border-color: rgba(37, 130, 216, 1);
	border-color: rgba(37, 130, 216, var(--tw-border-opacity));
}
.hover\:bg-white:hover{
	--tw-bg-opacity: 1;
	background-color: rgba(251, 249, 246, 1);
	background-color: rgba(251, 249, 246, var(--tw-bg-opacity));
}
.hover\:text-black:hover{
	--tw-text-opacity: 1;
	color: rgba(51, 51, 51, 1);
	color: rgba(51, 51, 51, var(--tw-text-opacity));
}
.hover\:text-blue:hover{
	--tw-text-opacity: 1;
	color: rgba(37, 130, 216, 1);
	color: rgba(37, 130, 216, var(--tw-text-opacity));
}
.hover\:text-white\/70:hover{
	color: rgba(251, 249, 246, 0.7);
}
.hover\:opacity-90:hover{
	opacity: 0.9;
}
.disabled\:pointer-events-none:disabled{
	pointer-events: none;
}
.disabled\:opacity-50:disabled{
	opacity: 0.5;
}
.group:hover .group-hover\:scale-\[1\.02\]{
	--tw-scale-x: 1.02;
	--tw-scale-y: 1.02;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(1.02) scaleY(1.02);
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:hover .group-hover\:text-blue{
	--tw-text-opacity: 1;
	color: rgba(37, 130, 216, 1);
	color: rgba(37, 130, 216, var(--tw-text-opacity));
}
.group:hover .group-hover\:opacity-100{
	opacity: 1;
}
@media (min-width: 390px){
	.xs\:w-\[75\%\]{
		width: 75%;
	}
}
@media (min-width: 712px){
	.sm\:sticky{
		position: sticky;
	}
	.sm\:bottom-6{
		bottom: 1.5rem;
	}
	.sm\:left-6{
		left: 1.5rem;
	}
	.sm\:top-\[calc\(var\(--header-height\)_\+_8rem\)\]{
		top: calc(48px + 8rem);
		top: calc(var(--header-height) + 8rem);
	}
	.sm\:col-span-1{
		grid-column: span 1 / span 1;
	}
	.sm\:col-span-10{
		grid-column: span 10 / span 10;
	}
	.sm\:col-span-3{
		grid-column: span 3 / span 3;
	}
	.sm\:col-span-4{
		grid-column: span 4 / span 4;
	}
	.sm\:col-span-6{
		grid-column: span 6 / span 6;
	}
	.sm\:col-span-8{
		grid-column: span 8 / span 8;
	}
	.sm\:col-span-9{
		grid-column: span 9 / span 9;
	}
	.sm\:col-start-2{
		grid-column-start: 2;
	}
	.sm\:col-start-5{
		grid-column-start: 5;
	}
	.sm\:col-start-6{
		grid-column-start: 6;
	}
	.sm\:float-left{
		float: left;
	}
	.sm\:my-\[8rem\]{
		margin-top: 8rem;
		margin-bottom: 8rem;
	}
	.sm\:-ml-\[calc\(100\%\+2rem\)\]{
		margin-left: calc((100% + 2rem) * -1);
		margin-left: calc(calc(100% + 2rem) * -1);
	}
	.sm\:mb-0{
		margin-bottom: 0px;
	}
	.sm\:mb-4{
		margin-bottom: 1rem;
	}
	.sm\:mb-6{
		margin-bottom: 1.5rem;
	}
	.sm\:mb-\[2rem\]{
		margin-bottom: 2rem;
	}
	.sm\:mb-\[3rem\]{
		margin-bottom: 3rem;
	}
	.sm\:mb-\[4rem\]{
		margin-bottom: 4rem;
	}
	.sm\:mb-\[6rem\]{
		margin-bottom: 6rem;
	}
	.sm\:mb-\[8rem\]{
		margin-bottom: 8rem;
	}
	.sm\:mt-0{
		margin-top: 0px;
	}
	.sm\:mt-2{
		margin-top: 0.5rem;
	}
	.sm\:mt-\[4rem\]{
		margin-top: 4rem;
	}
	.sm\:mt-\[6rem\]{
		margin-top: 6rem;
	}
	.sm\:mt-\[8rem\]{
		margin-top: 8rem;
	}
	.sm\:flex{
		display: flex;
	}
	.sm\:grid{
		display: grid;
	}
	.sm\:hidden{
		display: none;
	}
	.sm\:h-\[40px\]{
		height: 40px;
	}
	.sm\:h-\[44px\]{
		height: 44px;
	}
	.sm\:min-h-\[calc\(100vh-var\(--header-height\)-16rem\)\]{
		min-height: calc(100vh - 48px - 16rem);
		min-height: calc(100vh - var(--header-height) - 16rem);
	}
	.sm\:w-\[40px\]{
		width: 40px;
	}
	.sm\:w-\[44px\]{
		width: 44px;
	}
	.sm\:w-auto{
		width: auto;
	}
	.sm\:w-full{
		width: 100%;
	}
	.sm\:max-w-\[25vw\]{
		max-width: 25vw;
	}
	.sm\:max-w-\[33vw\]{
		max-width: 33vw;
	}
	.sm\:grid-cols-12{
		grid-template-columns: repeat(12, minmax(0, 1fr));
	}
	.sm\:grid-cols-2{
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	.sm\:grid-cols-4{
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	.sm\:items-center{
		align-items: center;
	}
	.sm\:gap-4{
		gap: 1rem;
	}
	.sm\:overflow-visible{
		overflow: visible;
	}
	.sm\:border-b-0{
		border-bottom-width: 0px;
	}
	.sm\:p-6{
		padding: 1.5rem;
	}
	.sm\:px-0{
		padding-left: 0px;
		padding-right: 0px;
	}
	.sm\:py-8{
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	.sm\:pb-0{
		padding-bottom: 0px;
	}
	.sm\:pb-6{
		padding-bottom: 1.5rem;
	}
	.sm\:pl-\[10\%\]{
		padding-left: 10%;
	}
	.sm\:pt-\[2rem\]{
		padding-top: 2rem;
	}

	.sm\:style-body--xl{
		font-family: var(--font-new-baskerville), Georgia, Cambria, "Times New Roman", Times, serif;
		font-size: 40px;
		line-height: 1.175;
	}
	.sm\:site-grid-span-2 {
		--span: 2;
		--gutter-width: calc(var(--gutter) * 1);
		width: calc(((100% - (11 * 16px)) / 12) * 2 + (16px * 1));
		width: calc(calc(calc(100% - calc(11 * 16px)) / 12) * 2 + calc(16px * 1));
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.sm\:site-grid-span-4 {
		--span: 4;
		--gutter-width: calc(var(--gutter) * 3);
		width: calc(((100% - (11 * 16px)) / 12) * 4 + (16px * 3));
		width: calc(calc(calc(100% - calc(11 * 16px)) / 12) * 4 + calc(16px * 3));
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.sm\:site-grid-span-5 {
		--span: 5;
		--gutter-width: calc(var(--gutter) * 4);
		width: calc(((100% - (11 * 16px)) / 12) * 5 + (16px * 4));
		width: calc(calc(calc(100% - calc(11 * 16px)) / 12) * 5 + calc(16px * 4));
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.sm\:site-grid-span-7 {
		--span: 7;
		--gutter-width: calc(var(--gutter) * 6);
		width: calc(((100% - (11 * 16px)) / 12) * 7 + (16px * 6));
		width: calc(calc(calc(100% - calc(11 * 16px)) / 12) * 7 + calc(16px * 6));
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.sm\:site-grid-span-8 {
		--span: 8;
		--gutter-width: calc(var(--gutter) * 7);
		width: calc(((100% - (11 * 16px)) / 12) * 8 + (16px * 7));
		width: calc(calc(calc(100% - calc(11 * 16px)) / 12) * 8 + calc(16px * 7));
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.sm\:site-grid-span-10 {
		--span: 10;
		--gutter-width: calc(var(--gutter) * 9);
		width: calc(((100% - (11 * 16px)) / 12) * 10 + (16px * 9));
		width: calc(calc(calc(100% - calc(11 * 16px)) / 12) * 10 + calc(16px * 9));
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.sm\:before\:content-\[\'\'\]::before{
		--tw-content: '';
		content: '';
		content: var(--tw-content);
	}
}
@media (min-width: 1112px){
	.md\:bottom-0{
		bottom: 0px;
	}
	.md\:bottom-8{
		bottom: 2rem;
	}
	.md\:left-0{
		left: 0px;
	}
	.md\:left-8{
		left: 2rem;
	}
	.md\:left-auto{
		left: auto;
	}
	.md\:right-0{
		right: 0px;
	}
	.md\:right-auto{
		right: auto;
	}
	.md\:top-\[0\.15em\]{
		top: 0.15em;
	}
	.md\:top-auto{
		top: auto;
	}
	.md\:col-span-1{
		grid-column: span 1 / span 1;
	}
	.md\:col-span-10{
		grid-column: span 10 / span 10;
	}
	.md\:col-span-2{
		grid-column: span 2 / span 2;
	}
	.md\:col-span-3{
		grid-column: span 3 / span 3;
	}
	.md\:col-span-4{
		grid-column: span 4 / span 4;
	}
	.md\:col-span-6{
		grid-column: span 6 / span 6;
	}
	.md\:col-span-8{
		grid-column: span 8 / span 8;
	}
	.md\:col-span-9{
		grid-column: span 9 / span 9;
	}
	.md\:col-start-2{
		grid-column-start: 2;
	}
	.md\:col-start-3{
		grid-column-start: 3;
	}
	.md\:col-start-4{
		grid-column-start: 4;
	}
	.md\:col-start-5{
		grid-column-start: 5;
	}
	.md\:col-start-7{
		grid-column-start: 7;
	}
	.md\:col-start-8{
		grid-column-start: 8;
	}
	.md\:my-16{
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
	.md\:my-\[10rem\]{
		margin-top: 10rem;
		margin-bottom: 10rem;
	}
	.md\:my-\[12rem\]{
		margin-top: 12rem;
		margin-bottom: 12rem;
	}
	.md\:my-\[8rem\]{
		margin-top: 8rem;
		margin-bottom: 8rem;
	}
	.md\:mb-0{
		margin-bottom: 0px;
	}
	.md\:mb-12{
		margin-bottom: 3rem;
	}
	.md\:mb-2{
		margin-bottom: 0.5rem;
	}
	.md\:mb-20{
		margin-bottom: 5rem;
	}
	.md\:mb-8{
		margin-bottom: 2rem;
	}
	.md\:mb-\[10rem\]{
		margin-bottom: 10rem;
	}
	.md\:mb-\[4rem\]{
		margin-bottom: 4rem;
	}
	.md\:mb-\[5rem\]{
		margin-bottom: 5rem;
	}
	.md\:mb-\[6rem\]{
		margin-bottom: 6rem;
	}
	.md\:mb-\[8rem\]{
		margin-bottom: 8rem;
	}
	.md\:mt-0{
		margin-top: 0px;
	}
	.md\:mt-4{
		margin-top: 1rem;
	}
	.md\:mt-\[10rem\]{
		margin-top: 10rem;
	}
	.md\:mt-\[6rem\]{
		margin-top: 6rem;
	}
	.md\:mt-\[8rem\]{
		margin-top: 8rem;
	}
	.md\:block{
		display: block;
	}
	.md\:inline{
		display: inline;
	}
	.md\:flex{
		display: flex;
	}
	.md\:grid{
		display: grid;
	}
	.md\:hidden{
		display: none;
	}
	.md\:aspect-square{
		aspect-ratio: 1 / 1;
	}
	.md\:aspect-video{
		aspect-ratio: 16 / 9;
	}
	.md\:h-36{
		height: 9rem;
	}
	.md\:h-4{
		height: 1rem;
	}
	.md\:h-7{
		height: 1.75rem;
	}
	.md\:h-\[20px\]{
		height: 20px;
	}
	.md\:h-\[48px\]{
		height: 48px;
	}
	.md\:h-\[64px\]{
		height: 64px;
	}
	.md\:h-full{
		height: 100%;
	}
	.md\:w-14{
		width: 3.5rem;
	}
	.md\:w-16{
		width: 4rem;
	}
	.md\:w-2\/5{
		width: 40%;
	}
	.md\:w-4{
		width: 1rem;
	}
	.md\:w-5{
		width: 1.25rem;
	}
	.md\:w-\[10rem\]{
		width: 10rem;
	}
	.md\:w-\[48px\]{
		width: 48px;
	}
	.md\:w-\[50\%\]{
		width: 50%;
	}
	.md\:w-\[64px\]{
		width: 64px;
	}
	.md\:w-auto{
		width: auto;
	}
	.md\:w-full{
		width: 100%;
	}
	.md\:max-w-\[320px\]{
		max-width: 320px;
	}
	.md\:cursor-auto{
		cursor: auto;
	}
	.md\:grid-cols-12{
		grid-template-columns: repeat(12, minmax(0, 1fr));
	}
	.md\:grid-cols-2{
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	.md\:grid-cols-3{
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	.md\:grid-cols-9{
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
	.md\:flex-row{
		flex-direction: row;
	}
	.md\:items-start{
		align-items: flex-start;
	}
	.md\:justify-start{
		justify-content: flex-start;
	}
	.md\:justify-between{
		justify-content: space-between;
	}
	.md\:gap-gutter{
		gap: 16px;
		gap: var(--gutter);
	}
	.md\:gap-y-\[8rem\]{
		row-gap: 8rem;
	}
	.md\:overflow-x-clip{
		overflow-x: clip;
	}
	.md\:border-none{
		border-style: none;
	}
	.md\:object-contain{
		-o-object-fit: contain;
		   object-fit: contain;
	}
	.md\:object-top{
		-o-object-position: top;
		   object-position: top;
	}
	.md\:p-8{
		padding: 2rem;
	}
	.md\:px-16{
		padding-left: 4rem;
		padding-right: 4rem;
	}
	.md\:px-\[17\.0391061452514\%\]{
		padding-left: 17.0391061452514%;
		padding-right: 17.0391061452514%;
	}
	.md\:px-\[18px\]{
		padding-left: 18px;
		padding-right: 18px;
	}
	.md\:py-0{
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.md\:py-48{
		padding-top: 12rem;
		padding-bottom: 12rem;
	}
	.md\:py-5{
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}
	.md\:py-7{
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
	}
	.md\:py-8{
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	.md\:pb-16{
		padding-bottom: 4rem;
	}
	.md\:pb-\[8px\]{
		padding-bottom: 8px;
	}
	.md\:pl-6{
		padding-left: 1.5rem;
	}
	.md\:pl-8{
		padding-left: 2rem;
	}
	.md\:pl-\[12\.5\%\]{
		padding-left: 12.5%;
	}
	.md\:pl-\[calc\(\(var\(--col-width\)\*1\)\+var\(--gutter\)\*1\)\]{
		padding-left: calc((calc((100% - (11 * 16px)) / 12) * 1) + 16px * 1);
		padding-left: calc((calc(calc(100% - calc(11 * 16px)) / 12) * 1) + 16px * 1);
		padding-left: calc((var(--col-width) * 1) + var(--gutter) * 1);
	}
	.md\:pr-1\/5{
		padding-right: 20%;
	}
	.md\:pr-4{
		padding-right: 1rem;
	}
	.md\:pr-\[15\%\]{
		padding-right: 15%;
	}
	.md\:pt-3{
		padding-top: 0.75rem;
	}
	.md\:pt-4{
		padding-top: 1rem;
	}
	.md\:pt-8{
		padding-top: 2rem;
	}
	.md\:pt-\[3rem\]{
		padding-top: 3rem;
	}
	.md\:pt-\[6px\]{
		padding-top: 6px;
	}
	.md\:text-center{
		text-align: center;
	}
	.md\:text-\[1\.15em\]{
		font-size: 1.15em;
	}
	.md\:text-\[56px\]{
		font-size: 56px;
	}
	.md\:opacity-0{
		opacity: 0;
	}

	.md\:style-h2{
		font-family: var(--font-baskerville-display), Georgia, Cambria, "Times New Roman", Times, serif;
		font-size: 46px;
		line-height: 1.08695652173913;
		letter-spacing: -0.01em;
	}

	@media (min-width: 1112px){
		.md\:style-h2{
			font-size: 68px;
			line-height: 1.088235294117647;
		}
	}

	.md\:style-h2 {
		font-feature-settings: "liga", "clig";
		font-variant-ligatures: common-ligatures;
	}

		.md\:style-h2.italic{
		letter-spacing: 0em;
	}

	.md\:style-body--xl{
		font-family: var(--font-new-baskerville), Georgia, Cambria, "Times New Roman", Times, serif;
		font-size: 40px;
		line-height: 1.175;
	}

	.md\:style-body--lg{
		font-family: var(--font-new-baskerville), Georgia, Cambria, "Times New Roman", Times, serif;
		font-size: 24px;
		line-height: 1.185185185185185;
	}

		.md\:style-body--lg.italic{
		letter-spacing: 0.005em;
	}

	.md\:style-tag--lg{
		font-family: var(--font-untitled), "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size: 18px;
		line-height: 1;
		letter-spacing: 0.005em;
	}
	.md\:site-grid-span-3 {
		--span: 3;
		--gutter-width: calc(var(--gutter) * 2);
		width: calc(((100% - (11 * 16px)) / 12) * 3 + (16px * 2));
		width: calc(calc(calc(100% - calc(11 * 16px)) / 12) * 3 + calc(16px * 2));
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.md\:site-grid-span-6 {
		--span: 6;
		--gutter-width: calc(var(--gutter) * 5);
		width: calc(((100% - (11 * 16px)) / 12) * 6 + (16px * 5));
		width: calc(calc(calc(100% - calc(11 * 16px)) / 12) * 6 + calc(16px * 5));
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.group:hover .md\:group-hover\:translate-x-\[var\(--overflow\)\]{
		--tw-translate-x: var(--overflow);
		transform: translate(var(--overflow), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
		transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
}
@media (min-width: 1342px){
	.footerFull\:col-span-10{
		grid-column: span 10 / span 10;
	}
	.footerFull\:col-start-9{
		grid-column-start: 9;
	}
	.footerFull\:grid-cols-10{
		grid-template-columns: repeat(10, minmax(0, 1fr));
	}
}
@media (min-width: 1512px){
	.lg\:col-span-2{
		grid-column: span 2 / span 2;
	}
	.lg\:col-span-3{
		grid-column: span 3 / span 3;
	}
	.lg\:col-span-4{
		grid-column: span 4 / span 4;
	}
	.lg\:col-span-6{
		grid-column: span 6 / span 6;
	}
	.lg\:col-span-9{
		grid-column: span 9 / span 9;
	}
	.lg\:col-start-4{
		grid-column-start: 4;
	}
	.lg\:col-start-6{
		grid-column-start: 6;
	}
	.lg\:pl-8{
		padding-left: 2rem;
	}
	.lg\:pr-\[calc\(\(var\(--col-width\)\*2\)\+var\(--gutter\)\*2\)\]{
		padding-right: calc((calc((100% - (11 * 16px)) / 12) * 2) + 16px * 2);
		padding-right: calc((calc(calc(100% - calc(11 * 16px)) / 12) * 2) + 16px * 2);
		padding-right: calc((var(--col-width) * 2) + var(--gutter) * 2);
	}
}
@media (max-width: 711px) and (orientation: portrait){
	.mobile\:block{
		display: block;
	}
	.mobile\:hidden{
		display: none;
	}
	.mobile\:collage-item-span-1 { grid-column: span 1 / span 1; }
	.mobile\:collage-item-span-2 { grid-column: span 2 / span 2; }
	.mobile\:collage-item-span-3 { grid-column: span 3 / span 3; }
	.mobile\:collage-item-span-4 { grid-column: span 4 / span 4; }
	.mobile\:collage-item-span-5 { grid-column: span 5 / span 5; }
	.mobile\:collage-item-span-6 { grid-column: span 6 / span 6; }
	.mobile\:collage-item-span-7 { grid-column: span 7 / span 7; }
	.mobile\:collage-item-span-8 { grid-column: span 8 / span 8; }
	.mobile\:collage-item-span-9 { grid-column: span 9 / span 9; }
	.mobile\:collage-item-span-10 { grid-column: span 10 / span 10; }
	.mobile\:collage-item-span-11 { grid-column: span 11 / span 11; }
	.mobile\:collage-item-span-12 { grid-column: span 12 / span 12; }
	.mobile\:collage-item-span-13 { grid-column: span 13 / span 13; }
	.mobile\:collage-item-span-14 { grid-column: span 14 / span 14; }
	.mobile\:collage-item-span-15 { grid-column: span 15 / span 15; }
	.mobile\:collage-item-span-16 { grid-column: span 16 / span 16; }
	.mobile\:collage-item-span-17 { grid-column: span 17 / span 17; }
	.mobile\:collage-item-span-18 { grid-column: span 18 / span 18; }
	.mobile\:collage-item-span-19 { grid-column: span 19 / span 19; }
	.mobile\:collage-item-span-20 { grid-column: span 20 / span 20; }
	.mobile\:collage-item-span-21 { grid-column: span 21 / span 21; }
	.mobile\:collage-item-span-22 { grid-column: span 22 / span 22; }
	.mobile\:collage-item-span-23 { grid-column: span 23 / span 23; }
	.mobile\:collage-item-span-24 { grid-column: span 24 / span 24; }

	.mobile\:collage-item-col-start-auto { grid-column-start: auto; }
	.mobile\:collage-item-col-start-1 { grid-column-start: 1; }
	.mobile\:collage-item-col-start-2 { grid-column-start: 2; }
	.mobile\:collage-item-col-start-3 { grid-column-start: 3; }
	.mobile\:collage-item-col-start-4 { grid-column-start: 4; }
	.mobile\:collage-item-col-start-5 { grid-column-start: 5; }
	.mobile\:collage-item-col-start-6 { grid-column-start: 6; }
	.mobile\:collage-item-col-start-7 { grid-column-start: 7; }
	.mobile\:collage-item-col-start-8 { grid-column-start: 8; }
	.mobile\:collage-item-col-start-9 { grid-column-start: 9; }
	.mobile\:collage-item-col-start-10 { grid-column-start: 10; }
	.mobile\:collage-item-col-start-11 { grid-column-start: 11; }
	.mobile\:collage-item-col-start-12 { grid-column-start: 12; }
	.mobile\:collage-item-col-start-13 { grid-column-start: 13; }
	.mobile\:collage-item-col-start-14 { grid-column-start: 14; }
	.mobile\:collage-item-col-start-15 { grid-column-start: 15; }
	.mobile\:collage-item-col-start-16 { grid-column-start: 16; }
	.mobile\:collage-item-col-start-17 { grid-column-start: 17; }
	.mobile\:collage-item-col-start-18 { grid-column-start: 18; }
	.mobile\:collage-item-col-start-19 { grid-column-start: 19; }
	.mobile\:collage-item-col-start-20 { grid-column-start: 20; }
	.mobile\:collage-item-col-start-21 { grid-column-start: 21; }
	.mobile\:collage-item-col-start-22 { grid-column-start: 22; }
	.mobile\:collage-item-col-start-23 { grid-column-start: 23; }
	.mobile\:collage-item-col-start-24 { grid-column-start: 24; }

	.mobile\:collage-item-row-start-auto { grid-row-start: auto; }
	.mobile\:collage-item-row-start-1 { grid-row-start: 1; }
	.mobile\:collage-item-row-start-2 { grid-row-start: 2; }
	.mobile\:collage-item-row-start-3 { grid-row-start: 3; }
	.mobile\:collage-item-row-start-4 { grid-row-start: 4; }
	.mobile\:collage-item-row-start-5 { grid-row-start: 5; }
	.mobile\:collage-item-row-start-6 { grid-row-start: 6; }
	.mobile\:collage-item-row-start-7 { grid-row-start: 7; }
	.mobile\:collage-item-row-start-8 { grid-row-start: 8; }
	.mobile\:collage-item-row-start-9 { grid-row-start: 9; }
	.mobile\:collage-item-row-start-10 { grid-row-start: 10; }
	.mobile\:collage-item-row-start-11 { grid-row-start: 11; }
	.mobile\:collage-item-row-start-12 { grid-row-start: 12; }
	.mobile\:collage-item-row-start-13 { grid-row-start: 13; }
	.mobile\:collage-item-row-start-14 { grid-row-start: 14; }
	.mobile\:collage-item-row-start-15 { grid-row-start: 15; }
	.mobile\:collage-item-row-start-16 { grid-row-start: 16; }
	.mobile\:collage-item-row-start-17 { grid-row-start: 17; }
	.mobile\:collage-item-row-start-18 { grid-row-start: 18; }
	.mobile\:collage-item-row-start-19 { grid-row-start: 19; }
	.mobile\:collage-item-row-start-20 { grid-row-start: 20; }
	.mobile\:collage-item-row-start-21 { grid-row-start: 21; }
	.mobile\:collage-item-row-start-22 { grid-row-start: 22; }
	.mobile\:collage-item-row-start-23 { grid-row-start: 23; }
	.mobile\:collage-item-row-start-24 { grid-row-start: 24; }
}
@media (orientation: portrait){
	.portrait\:-mt-\[25svh\]{
		margin-top: -25svh;
	}
}
.\[\&\.is-dragging\]\:cursor-grabbing.is-dragging{
	cursor: grabbing;
}
.\[\&\>div\]\:bg-\[rgba\(255\2c 0\2c 0\2c 0\.07\)\]>div{
	background-color: rgba(255,0,0,0.07);
}
.\[\&_\*\]\:bg-\[\#0ff\]\/10 *{
	background-color: rgba(0, 255, 255, 0.1);
}
.\[\&_em\]\:italic em{
	font-style: italic;
}
.\[\&_em\]\:italic em.style-h2{
	letter-spacing: 0em;
}
.\[\&_em\]\:italic em.style-body--lg{
	letter-spacing: 0.005em;
}
.\[\&_em\]\:italic em.style-body{
	letter-spacing: 0.005em;
}
@media(max-width:1111px){
	.\[\@media\(max-width\:1111px\)\]\:\[\&\>\*\:nth-child\(n\+3\)\]\:hidden>*:nth-child(n+3){
		display: none;
	}
}
@media(max-width:711px){
	.\[\@media\(max-width\:711px\)\]\:\[\&\>\*\:nth-child\(n\+2\)\]\:hidden>*:nth-child(n+2){
		display: none;
	}
}
@media(max-width:767px){
	.\[\@media\(max-width\:767px\)\]\:\[\&_br\]\:hidden br{
		display: none;
	}
}
@media (max-height:1000px){
	.\[\@media_\(max-height\:1000px\)\]\:text-center{
		text-align: center;
	}
}
