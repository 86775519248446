@layer components {
  .home-page-collage {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(24, 1fr);
    gap: 1rem 1rem;
  }
  @media (orientation: portrait) and (max-width: 767px) {
    .home-page-collage {
      display: grid;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(24, 1fr);
      gap: 1rem 1rem;
    }
  }
}