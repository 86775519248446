/* http://meyerweb.com/eric/tools/css/reset/
	 v2.0 | 20110126
	 License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	border-radius: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*,
*:before,
*:after {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-decoration-thickness: from-font;
}

img {
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;
}

button {
	cursor: pointer;
	color: inherit;
}

*:not(img) {
	touch-action: manipulation;
}

@media (prefers-reduced-motion: reduce) {
	* {
		animation-play-state: paused !important;
		transition: none !important;
		scroll-behavior: auto !important;
	}
}

.overflow-hidden {
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
}
