@layer utilities {

	.rounded-strong {
		@apply rounded-[4px] sm:rounded-[6px] md:rounded-[8px];
	}

	.rounded-mid {
		@apply rounded-[2px] sm:rounded-[4px] md:rounded-[6px];
	}

	.rounded-weak {
		@apply rounded-[4px];
	}

	.text-balance {
		text-wrap: balance;
	}

	.inset-center {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}