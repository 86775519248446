/* Style text selection */
::selection {
	@apply text-white bg-black;
}

:root {
	--header-height: 48px;

	--gutter: 16px;
	--site-margin: 16px;
	--site-max-width: 1824px;

	--gutter-space-total: calc(11 * var(--gutter));
	--col-space-total: calc(100% - var(--gutter-space-total));
	--col-width: calc(var(--col-space-total) / 12);

	@media screen and (min-width: 820px) {
		--gutter: 16px;
		--site-margin: 24px;
	}

	@media screen and (min-width: 1024px) {
		--header-height: 68px;
	}

	@media screen and (min-width: 1512px) {
		--gutter: 16px;
		--site-margin: 32px;
	}

	--soft-bounce: linear(
		0 0%,
		0.001781 1%,
		0.006992 2%,
		0.015434 3%,
		0.026907 4%,
		0.041209 5%,
		0.058142 6%,
		0.077507 7.000000000000001%,
		0.099107 8%,
		0.122748 9%,
		0.148238 10%,
		0.175391 11%,
		0.204022 12%,
		0.233953 13%,
		0.26501 14.000000000000002%,
		0.297022 15%,
		0.329828 16%,
		0.363269 17%,
		0.397193 18%,
		0.431456 19%,
		0.465918 20%,
		0.500448 21%,
		0.534919 22%,
		0.569214 23%,
		0.603221 24%,
		0.636834 25%,
		0.669956 26%,
		0.702495 27%,
		0.734368 28.000000000000004%,
		0.765498 28.999999999999996%,
		0.795813 30%,
		0.825249 31%,
		0.85375 32%,
		0.881263 33%,
		0.907744 34%,
		0.933153 35%,
		0.957458 36%,
		0.980631 37%,
		1.00265 38%,
		1.023497 39%,
		1.043162 40%,
		1.061637 41%,
		1.07892 42%,
		1.095012 43%,
		1.10992 44%,
		1.123654 45%,
		1.136226 46%,
		1.147654 47%,
		1.157958 48%,
		1.16716 49%,
		1.175287 50%,
		1.182367 51%,
		1.188429 52%,
		1.193506 53%,
		1.197632 54%,
		1.200843 55.00000000000001%,
		1.203175 56.00000000000001%,
		1.204667 56.99999999999999%,
		1.205357 57.99999999999999%,
		1.205287 59%,
		1.204495 60%,
		1.203023 61%,
		1.200912 62%,
		1.198203 63%,
		1.194937 64%,
		1.191155 65%,
		1.186898 66%,
		1.182206 67%,
		1.177118 68%,
		1.171673 69%,
		1.16591 70%,
		1.159864 71%,
		1.153574 72%,
		1.147073 73%,
		1.140395 74%,
		1.133575 75%,
		1.126642 76%,
		1.119629 77%,
		1.112563 78%,
		1.105472 79%,
		1.098383 80%,
		1.091321 81%,
		1.084309 82%,
		1.077369 83%,
		1.070522 84%,
		1.063788 85%,
		1.057183 86%,
		1.050725 87%,
		1.044429 88%,
		1.038308 89%,
		1.032375 90%,
		1.02664 91%,
		1.021114 92%,
		1.015805 93%,
		1.010721 94%,
		1.005866 95%,
		1.001247 96%,
		0.996868 97%,
		0.99273 98%,
		0.988837 99%,
		0.985188 100%
	);
}

.site-wrapper {
	width: 100%;
	max-width: 1824px;
	margin-left: auto;
	margin-right: auto;
}

.site-grid {
	width: 100%;
	max-width: var(--site-max-width);
	margin-left: auto;
	margin-right: auto;
	@apply grid grid-cols-2 sm:grid-cols-12 gap-x-[var(--gutter)] px-[var(--site-margin)];
}

.top-nav-gap {
	@apply mt-24 md:mt-32;
}

.about-layout .content-block--media {
	@apply my-24 md:my-32;
}

.tickertape__content {
	animation: tickertape 26s linear infinite;
}
.tickertape:hover .tickertape__content {
	animation-play-state: paused;
}

.blockquote {
	@apply relative style-body--lg md:style-body--xl my-[2rem] md:my-[3rem];

	&:before,
	&::after {
		@apply text-white;
		content: '';
		display: inline-block;
		height: 0.3em;
		/* ratio: 0.65186247 */
		width: 0.4em;
		margin-right: 0.2em;
		background-image: url('/svgs/quote.svg');
		background-size: contain;
		transform: translateY(-0.34em);
	}

	&::after {
		margin-right: 0;
		margin-left: 0.1em;
		transform: rotate(180deg) translateY(0.34em);
	}

	&.-hide-quotes {
		&:before,
		&::after {
			display: none;
		}
	}
}

@keyframes tickertape {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-100%, 0, 0);
	}
}

@keyframes carouselBullet {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}

@keyframes fadeInMoveUpDown {
	0% {
		opacity: 0;
		transform: translateY(2%); /* Starting position */
		animation-timing-function: var(--soft-bounce);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
		animation-timing-function: var(--soft-bounce);
	}
}

.intro--logo {
	transform: translateY(2%); /* Start slightly below its final position */
	animation: fadeInMoveUpDown 1.3s forwards;
}

.dots.autoplaying .dot.selected::before {
	animation: carouselBullet 4000ms linear 1;
	animation-fill-mode: both;
}

.content-block--info-box + .content-block--info-box {
	@apply mt-[-2rem];
}

@layer utilities {
	.site-grid-span-1 {
		--span: 1;
		--gutter-width: calc(var(--gutter) * 1);
		width: calc(var(--col-width) * var(--span));
	}
	.site-grid-span-2 {
		--span: 2;
		--gutter-width: calc(var(--gutter) * 1);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.site-grid-span-3 {
		--span: 3;
		--gutter-width: calc(var(--gutter) * 2);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.site-grid-span-4 {
		--span: 4;
		--gutter-width: calc(var(--gutter) * 3);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.site-grid-span-5 {
		--span: 5;
		--gutter-width: calc(var(--gutter) * 4);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.site-grid-span-6 {
		--span: 6;
		--gutter-width: calc(var(--gutter) * 5);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.site-grid-span-7 {
		--span: 7;
		--gutter-width: calc(var(--gutter) * 6);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.site-grid-span-8 {
		--span: 8;
		--gutter-width: calc(var(--gutter) * 7);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.site-grid-span-9 {
		--span: 9;
		--gutter-width: calc(var(--gutter) * 8);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.site-grid-span-10 {
		--span: 10;
		--gutter-width: calc(var(--gutter) * 9);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
	.site-grid-span-11 {
		--span: 11;
		--gutter-width: calc(var(--gutter) * 10);
		width: calc(var(--col-width) * var(--span) + var(--gutter-width));
	}
}

@layer utilities {
	.collage-item-span-1 { grid-column: span 1 / span 1; }
	.collage-item-span-2 { grid-column: span 2 / span 2; }
	.collage-item-span-3 { grid-column: span 3 / span 3; }
	.collage-item-span-4 { grid-column: span 4 / span 4; }
	.collage-item-span-5 { grid-column: span 5 / span 5; }
	.collage-item-span-6 { grid-column: span 6 / span 6; }
	.collage-item-span-7 { grid-column: span 7 / span 7; }
	.collage-item-span-8 { grid-column: span 8 / span 8; }
	.collage-item-span-9 { grid-column: span 9 / span 9; }
	.collage-item-span-10 { grid-column: span 10 / span 10; }
	.collage-item-span-11 { grid-column: span 11 / span 11; }
	.collage-item-span-12 { grid-column: span 12 / span 12; }
	.collage-item-span-13 { grid-column: span 13 / span 13; }
	.collage-item-span-14 { grid-column: span 14 / span 14; }
	.collage-item-span-15 { grid-column: span 15 / span 15; }
	.collage-item-span-16 { grid-column: span 16 / span 16; }
	.collage-item-span-17 { grid-column: span 17 / span 17; }
	.collage-item-span-18 { grid-column: span 18 / span 18; }
	.collage-item-span-19 { grid-column: span 19 / span 19; }
	.collage-item-span-20 { grid-column: span 20 / span 20; }
	.collage-item-span-21 { grid-column: span 21 / span 21; }
	.collage-item-span-22 { grid-column: span 22 / span 22; }
	.collage-item-span-23 { grid-column: span 23 / span 23; }
	.collage-item-span-24 { grid-column: span 24 / span 24; }

	.collage-item-col-start-auto { grid-column-start: auto; }
	.collage-item-col-start-1 { grid-column-start: 1; }
	.collage-item-col-start-2 { grid-column-start: 2; }
	.collage-item-col-start-3 { grid-column-start: 3; }
	.collage-item-col-start-4 { grid-column-start: 4; }
	.collage-item-col-start-5 { grid-column-start: 5; }
	.collage-item-col-start-6 { grid-column-start: 6; }
	.collage-item-col-start-7 { grid-column-start: 7; }
	.collage-item-col-start-8 { grid-column-start: 8; }
	.collage-item-col-start-9 { grid-column-start: 9; }
	.collage-item-col-start-10 { grid-column-start: 10; }
	.collage-item-col-start-11 { grid-column-start: 11; }
	.collage-item-col-start-12 { grid-column-start: 12; }
	.collage-item-col-start-13 { grid-column-start: 13; }
	.collage-item-col-start-14 { grid-column-start: 14; }
	.collage-item-col-start-15 { grid-column-start: 15; }
	.collage-item-col-start-16 { grid-column-start: 16; }
	.collage-item-col-start-17 { grid-column-start: 17; }
	.collage-item-col-start-18 { grid-column-start: 18; }
	.collage-item-col-start-19 { grid-column-start: 19; }
	.collage-item-col-start-20 { grid-column-start: 20; }
	.collage-item-col-start-21 { grid-column-start: 21; }
	.collage-item-col-start-22 { grid-column-start: 22; }
	.collage-item-col-start-23 { grid-column-start: 23; }
	.collage-item-col-start-24 { grid-column-start: 24; }

	.collage-item-row-start-auto { grid-row-start: auto; }
	.collage-item-row-start-1 { grid-row-start: 1; }
	.collage-item-row-start-2 { grid-row-start: 2; }
	.collage-item-row-start-3 { grid-row-start: 3; }
	.collage-item-row-start-4 { grid-row-start: 4; }
	.collage-item-row-start-5 { grid-row-start: 5; }
	.collage-item-row-start-6 { grid-row-start: 6; }
	.collage-item-row-start-7 { grid-row-start: 7; }
	.collage-item-row-start-8 { grid-row-start: 8; }
	.collage-item-row-start-9 { grid-row-start: 9; }
	.collage-item-row-start-10 { grid-row-start: 10; }
	.collage-item-row-start-11 { grid-row-start: 11; }
	.collage-item-row-start-12 { grid-row-start: 12; }
	.collage-item-row-start-13 { grid-row-start: 13; }
	.collage-item-row-start-14 { grid-row-start: 14; }
	.collage-item-row-start-15 { grid-row-start: 15; }
	.collage-item-row-start-16 { grid-row-start: 16; }
	.collage-item-row-start-17 { grid-row-start: 17; }
	.collage-item-row-start-18 { grid-row-start: 18; }
	.collage-item-row-start-19 { grid-row-start: 19; }
	.collage-item-row-start-20 { grid-row-start: 20; }
	.collage-item-row-start-21 { grid-row-start: 21; }
	.collage-item-row-start-22 { grid-row-start: 22; }
	.collage-item-row-start-23 { grid-row-start: 23; }
	.collage-item-row-start-24 { grid-row-start: 24; }
}

mux-player {
	--controls: none;

	--dialog: none;

	--loading-indicator: none;

	--play-button: none;
	--live-button: none;
	--seek-backward-button: none;
	--seek-forward-button: none;
	--mute-button: none;
	--captions-button: none;
	--airplay-button: none;
	--pip-button: none;
	--fullscreen-button: none;
	--cast-button: none;
	--playback-rate-button: none;
	--volume-range: none;
	--time-range: none;
	--time-display: none;
	--duration-display: none;
	--rendition-selectmenu: none;

	--center-controls: none;
	--bottom-play-button: none;
}
