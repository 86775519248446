html {
	height: 100%;
	text-rendering: optimizeSpeed;
	scroll-behavior: smooth;
	font-feature-settings: 'kern' 1;
	/* this should be the smallest possible base font size (i.e. at mobile) */
	font-size: 16px;
}

body {
	height: auto;
	min-height: 100%;
	@apply style-body text-black bg-white;
}

a {
	@apply no-underline;
}

a:not([class*="text-"], [class*="underline"]) {
	@apply text-black hover:text-blue transition;
	/* @apply text-black hover:text-blue underline underline-offset-[0.25em] decoration-blue/0 hover:decoration-blue transition; */
}

/* a,
button {
	@apply transition duration-150;
} */

a:focus,
button:focus {
	outline: none;
}
